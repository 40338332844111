import Vue from 'vue'
import VueRouter from 'vue-router'

import AdminSection from '@/views/admin/AdminSection.vue'
import AvailabilitiesList from '@/views/admin/AvailabilitiesList.vue'
import AvailabilityForm from '@/views/admin/AvailabilityForm.vue'

import ClusterFixtures from '@/views/cluster/ClusterFixtures.vue'
import ClusterSection from '@/views/cluster/ClusterSection.vue'
import FixturesBuilder from '@/views/cluster/FixturesBuilder.vue'

import FixturesList from '@/views/admin/FixturesList.vue'
import GameForm from '@/views/admin/GameForm.vue'
import GamesList from '@/views/admin/GamesList.vue'
import PlayersList from '@/views/admin/PlayersList.vue'
import TeamsAllocation from '@/views/admin/TeamsAllocation.vue'

import FixtureInfo from '@/views/info/FixtureInfo.vue'
import InfoSection from '@/views/info/InfoSection.vue'

import { EventBus } from '@/event-bus'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/2022/u6/fixture/' },
  { path: '/admin', redirect: '/admin/2022/u6/teams' },
  { path: '/cluster', redirect: '/cluster/2022/u6/fixtures/' },
  { path: '/cluster/admin', redirect: '/cluster/2022/u6/fixtures-builder/' },
  {
    path: '/2022/u6/',
    component: InfoSection,
    children: [
      {
        path: 'fixture/:round?',
        name: 'fixture-info',
        component: FixtureInfo,
        props: true,
      },
    ],
  },
  {
    path: '/admin/2022/:ageGroup',
    component: AdminSection,
    props: true,
    children: [
      {
        path: 'teams/:round?',
        name: 'teams',
        component: TeamsAllocation,
        props: true,
      },
      {
        path: 'games/:round?',
        name: 'games',
        component: GamesList,
        props: true,
      },
      {
        path: 'game/:round/:id?',
        name: 'game',
        component: GameForm,
        props: true,
      },
      {
        path: 'availabilities/:round?',
        name: 'availabilities',
        component: AvailabilitiesList,
        props: true,
      },
      {
        path: 'availability/:round/:playerId',
        name: 'availability',
        component: AvailabilityForm,
        props: true,
      },
      {
        path: 'players',
        name: 'players',
        component: PlayersList,
      },
      {
        path: 'fixtures',
        name: 'fixtures',
        component: FixturesList,
      },
      {
        path: 'fixtures-builder/:round?',
        name: 'fixtures-builder',
        component: FixturesBuilder,
        props: true,
      },
    ],
  },
  {
    path: '/cluster/2022/:ageGroup',
    component: ClusterSection,
    props: true,
    children: [
      {
        path: 'fixtures/:round?',
        name: 'cluster-fixtures',
        component: ClusterFixtures,
        props: true,
      },
    ],
  },
]

const router = new VueRouter({
  routes,
})

router.afterEach(() => {
  EventBus.$emit('feedback:clear')
})

export default router
