<template>
  <v-card flat class="px-4 pb-6">
    <v-card-title>
      <RoundNavigator title="Availability" />
    </v-card-title>
    <v-row class="mb-4">
      <v-col cols="12" class="text-subtitle-1 gray--text">
        <Available :available="1" :label="`Available: ${count(1)}`" />
        <Available :available="0" :label="`Not available: ${count(0)}`" />
        <Available :available="null" :label="`Unknown: ${count(null)}`" />
      </v-col>
    </v-row>
    <v-data-table v-bind="listProps" v-on="listEvents">
      <template v-slot:[`item.number`]="{ item }">
        <Player :player="item" />
      </template>
      <template v-slot:[`item.available`]="{ item }">
        <Available :available="item.available" />
      </template>
      <template v-slot:[`item.parent_helper`]="{ item }">
        <Available
          v-if="!!item.parent_helper"
          :available="item.parent_helper"
          :label="item.parent_first_name"
        />
      </template>
      <template v-slot:[`item.venue_helper`]="{ item }">
        <div class="my-2">
          <Available
            v-if="!!item.venue_helper"
            :available="item.venue_helper"
            :label="item.helper_first_name"
          />
          <div v-if="item.venue_helper" class="text-caption">
            {{ helperRoles[item.id] }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{
          item.updated_at
            ? textTimestamp(item.updated_at)
            : textTimestamp(item.created_at)
        }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { format, parseISO } from 'date-fns'
import ApiService from '@/services/ApiService'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'
import ListMixin from '@/mixins/ListMixin'
import Available from '@/components/chips/Available'
import Player from '@/components/chips/Player'
import RoundNavigator from '@/components/admin/RoundNavigator'

export default {
  name: 'AvailabilitiesList',
  components: {
    Available,
    Player,
    RoundNavigator,
  },
  mixins: [FixtureBasedMixin, ListMixin],
  data() {
    return {
      module: 'availabilities',
      formRoute: 'availability',
      helperRoles: {},
      headers: [
        { text: 'Player', value: 'number' },
        { text: 'Available', value: 'available', align: 'center' },
        { text: 'Parent Helper', value: 'parent_helper', align: 'center' },
        { text: 'Venue Helper', value: 'venue_helper', align: 'center' },
        { text: 'Comment', value: 'comment' },
        { text: 'Submitted', value: 'updated_at' },
      ],
    }
  },
  async mounted() {
    this.loading = true
    try {
      this.helperRoles = {}
      const rolesData = (
        await ApiService.list('helperRoles', {
          fixture_id: this.fixture.id,
        })
      ).data
      rolesData.forEach((data) => {
        this.helperRoles[data.id] = data.helper_roles
          .sort((role1, role2) => role1.order - role2.order)
          .map((role) => role.name)
          .join(', ')
      })
    } finally {
      this.loading = false
    }
  },
  methods: {
    loadDataParams() {
      return {
        fixture_id: this.fixture.id,
      }
    },
    formRouteParams(item) {
      return {
        round: this.fixture.number,
        playerId: item.player_id,
      }
    },
    recordsPerPage() {
      return 1000
    },
    textTimestamp(isoDate) {
      if (!isoDate) {
        return ''
      }
      const date = parseISO(isoDate)
      return format(date, 'eeee h:mmaaa')
    },
    count(available) {
      return this.listData && this.listData.data
        ? this.listData.data.filter((record) => record.available === available)
            .length
        : '...'
    },
  },
}
</script>
