<template>
  <v-app>
    <Feedback />
    <Progress v-if="loading" />
    <router-view v-else :key="$route.path" class="print-content pb-4" />
    <Footer :version="versionNo" class="no-print" />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import ApiService from '@/services/ApiService'
import Feedback from '@/components/utils/Feedback'
import Footer from '@/components/utils/Footer'
import Progress from '@/components/utils/Progress'

export default {
  name: 'App',
  components: {
    Feedback,
    Footer,
    Progress,
  },
  data: () => ({
    versionNo: process.env.VUE_APP_VERSION,
    loading: true,
  }),
  computed: {
    ...mapState(['fixtures']),
  },
  async mounted() {
    this.loading = true
    try {
      if (this.fixtures.length < 1) {
        const fixtures = (await ApiService.all('fixtures')).data
        await this.$store.dispatch('setFixtures', fixtures)
      }
    } finally {
      this.loading = false
    }
  },
}
</script>

<style scoped>
.theme--light.v-application {
  background-color: #eeeeee;
}
</style>

<style>
.print-only {
  display: none;
}
@media print {
  @page {
    size: landscape;
    margin: 0;
  }
  body {
    margin: 1cm;
  }
  .print-content {
    margin-top: -5rem;
    margin-left: -5rem;
  }
  .v-application .text-h5 {
    font-size: 1.3rem !important;
  }
  .v-application--wrap,
  .v-application .v-main.grey {
    background-color: #fff !important;
  }
  .print-only {
    display: unset;
  }
  .no-print,
  .no-print *,
  .v-chip__close {
    display: none !important;
  }
}
</style>
