<template>
  <v-card flat class="px-4 pb-6">
    <v-card-title>
      <RoundNavigator title="Games">
        <v-btn
          v-if="!clusterGames"
          color="green"
          dark
          class="mr-8"
          @click="add"
        >
          <v-icon left> mdi-plus </v-icon> Add Game
        </v-btn>
      </RoundNavigator>
    </v-card-title>
    <v-alert type="warning" outlined class="mt-4">
      Currently can only be set in
      <router-link
        :to="{
          name: 'fixtures-builder',
          params: { ageGroup: this.ageGroup, round: this.fixture.number },
        }"
      >
        Fixture Builder
      </router-link>
    </v-alert>
    <v-data-table v-if="false" v-bind="listProps" v-on="listEvents">
      <template v-slot:[`item.team`]="{ item }">
        <Team :name="item.team" color="primary" />
      </template>
      <template v-slot:[`item.opposition`]="{ item }">
        <Team :name="item.opposition" :color="item.opposition_color" />
      </template>
      <template v-slot:[`item.time`]="{ item }">
        {{ textTime(item.time) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ApiService from '@/services/ApiService'
import { format, parse } from 'date-fns'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'
import ListMixin from '@/mixins/ListMixin'
import RoundNavigator from '@/components/admin/RoundNavigator'
import Team from '@/components/chips/Team'

export default {
  name: 'GamesList',
  components: {
    RoundNavigator,
    Team,
  },
  mixins: [FixtureBasedMixin, ListMixin],
  data() {
    return {
      ageGroup: 'u6',
      clusterGames: false,
      module: 'games',
      formRoute: 'game',
      headers: [
        { text: 'Time', value: 'time', align: 'center' },
        { text: 'Pitch', value: 'pitch', align: 'center' },
        { text: 'Team', value: 'team', align: 'center' },
        { text: 'Opposition', value: 'opposition', align: 'center' },
      ],
    }
  },
  async mounted() {
    const clusterGames = (
      await ApiService.get('games', 'list', {
        ageGroup: this.ageGroup,
        fixtureId: this.fixture.id,
      })
    ).data
    this.clusterGames = clusterGames.some(
      (game) => game.home_team_id || game.away_team_id,
    )
  },
  methods: {
    loadDataParams() {
      return {
        fixture_id: this.fixture.id,
      }
    },
    formRouteParams() {
      return {
        round: this.fixture.number,
      }
    },
    textTime(isoTime) {
      return format(parse(isoTime, 'HH:mm:ss', new Date()), 'h:mmaaa')
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
