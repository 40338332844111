<template>
  <v-row>
    <Progress v-if="loading" />
    <FixtureDetails>
      <template v-if="!loading">
        <v-row
          v-if="teamsSelection.length > 1"
          dense
          class="grey--text text--darken-2 mt-8"
        >
          <v-col cols="12" md="4" offset-md="4">
            <v-select
              filled
              v-model="activeTeam"
              :items="teamsSelection"
              item-text="name"
              item-value="id"
              label="Show games for"
            >
              <template v-slot:selection="{ item }">
                <Team
                  v-if="item.color"
                  :name="item.name"
                  :color="item.color"
                  class="mt-4"
                />
                <template v-else>
                  {{ item.name }}
                </template>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="teamsSelection.length > 1"
          class="grey--text text--darken-2"
        >
          <v-col cols="12" md="6" v-for="round in [1, 2]" :key="round">
            <v-card outlined class="pa-4">
              <p class="text-h5 text-center">Game {{ round }}</p>
              <GameInfo
                v-for="(game, index) in roundGames(round)"
                :key="index"
                :game="game"
              />
            </v-card>
          </v-col>
        </v-row>
      </template>
    </FixtureDetails>
  </v-row>
</template>

<script>
import ApiService from '@/services/ApiService'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'
import FixtureDetails from '@/components/info/FixtureDetails'
import GameInfo from '@/components/info/GameInfo'
import Progress from '@/components/utils/Progress'

export default {
  name: 'ClusterFixtures',
  mixins: [FixtureBasedMixin],
  components: {
    FixtureDetails,
    GameInfo,
    Progress,
  },
  props: {
    ageGroup: String,
  },
  data: () => ({
    teams: [],
    games: [],
    activeTeam: -1,
    loading: false,
  }),
  computed: {
    teamsSelection() {
      return [
        {
          id: -1,
          name: 'All Teams',
        },
        ...this.teams.filter((team) => {
          const gameInRound1 = this.games.some(
            (game) =>
              game.home_team_id === team.id || game.away_team_id === team.id,
          )
          const gameInRound2 = this.games.some(
            (game) =>
              game.home_team_id === team.id || game.away_team_id === team.id,
          )
          return gameInRound1 || gameInRound2
        }),
      ]
    },
  },
  async mounted() {
    this.loading = true
    try {
      this.teams = (await ApiService.get('clubs/teams', this.ageGroup)).data
      this.games = (
        await ApiService.get('games', 'list', {
          ageGroup: this.ageGroup,
          fixtureId: this.fixture.id,
        })
      ).data
    } finally {
      this.loading = false
    }
  },
  methods: {
    roundGames(round) {
      const roundGames = this.games.filter((game) => {
        const matchingRound = game.order === round
        const participants = game.home_team_id || game.away_team_id
        let matchingTeam = true
        if (this.activeTeam > 0) {
          matchingTeam =
            game.home_team_id === this.activeTeam ||
            game.away_team_id === this.activeTeam
        }
        return matchingRound && participants && matchingTeam
      })
      roundGames.sort((game1, game2) => {
        return game1.pitch_id - game2.pitch_id
      })
      return roundGames
    },
  },
}
</script>
