<template>
  <div>
    <v-app-bar app flat dark clipped-left color="primary" class="no-print">
      <v-spacer></v-spacer>
      <div class="text-h6">
        Floreat Athena - 2022 - Under {{ ageGroup.substring(1) }}
      </div>
      <v-spacer></v-spacer>
      <UserInfo />
    </v-app-bar>
    <Menu class="no-print" />
    <v-main class="main">
      <v-container fluid v-if="loggedIn()">
        <router-view :key="`subroute-${$route.path}`" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Menu from '@/components/admin/Menu'
import UserInfo from '@/components/admin/UserInfo'

export default {
  name: 'AdminSection',
  components: {
    Menu,
    UserInfo,
  },
  props: {
    ageGroup: String,
  },
  computed: {
    ...mapGetters(['loggedIn']),
  },
}
</script>

<style scoped>
.main {
  padding-left: 56px !important;
}
</style>
