<template>
  <v-btn color="green" dark @click="switchAgeGroup">
    Switch to Under {{ ageGroup === 'u6' ? '7' : '6' }}
  </v-btn>
</template>

<script>
export default {
  name: 'AgeGroupSwitcher',
  props: {
    ageGroup: String,
  },
  methods: {
    switchAgeGroup() {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          ageGroup: this.ageGroup === 'u6' ? 'u7' : 'u6',
        },
      })
    },
  },
}
</script>
