<template>
  <v-dialog persistent :value="true" width="500">
    <v-card>
      <v-card-title>
        <span class="text-h5">Select a player</span>
      </v-card-title>
      <v-card-text class="mt-4 mb-n8">
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Number"
              v-model="number"
              type="number"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field
              label="First name"
              v-model="firstName"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-alert text type="error" v-if="notFound">
          Could not find a player. Please double check number and first name or
          contact team manager
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="find"
          :loading="finding"
          :disabled="finding"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from '@/services/ApiService'

export default {
  name: 'PlayerSelector',
  data: () => ({
    number: null,
    firstName: null,
    finding: false,
    notFound: false,
  }),
  methods: {
    async find() {
      this.finding = true
      const player = (
        await ApiService.find('players', {
          number: this.number,
          first_name: this.firstName,
        })
      ).data
      if (player.id) {
        await this.$store.dispatch('setPlayer', player)
      } else {
        this.notFound = true
      }
      this.finding = false
    },
  },
}
</script>
