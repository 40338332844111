<template>
  <v-card flat class="teams px-4 pb-6">
    <Progress v-if="loading" />
    <v-card-title>
      <RoundNavigator title="Teams Allocation" />
    </v-card-title>
    <v-row>
      <v-col cols="3" xl="2" v-for="team in teams" :key="team.key">
        <v-card height="100%" :class="{ 'no-print': team.inactive }">
          <v-card-text class="team">
            <div
              class="text-h5 team-name mb-2"
              :class="{
                inactive: team.inactive,
                'text--primary': !team.inactive,
                'text--lighten-3': team.inactive,
              }"
            >
              {{ team.name }}
            </div>
            <template v-if="!team.inactive">
              <div class="leader mb-1">
                <v-edit-dialog
                  :return-value.sync="team.game_leader"
                  @open="team.game_leader = leader(team, 0)"
                  large
                >
                  Game Leader: <b class="leader-name">{{ leader(team, 0) }}</b>
                  <template v-slot:input>
                    <v-text-field
                      v-model="team.game_leader"
                      label="Game Leader"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
              <div class="leader mb-1">
                <v-edit-dialog
                  :return-value.sync="team.helper"
                  @open="team.helper = leader(team, 1)"
                  large
                >
                  Helper: <b class="leader-name">{{ leader(team, 1) }}</b>
                  <template v-slot:input>
                    <v-text-field
                      v-model="team.helper"
                      label="Helper"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
              <draggable
                v-model="team.players"
                group="players"
                class="team-players mt-2 pt-6"
              >
                <Player
                  v-for="player in team.players"
                  :key="player.id"
                  :player="player"
                  class="mb-4 player"
                />
              </draggable>
            </template>
          </v-card-text>
          <div class="text-overline pitches pa-2 white">
            {{ team.pitches }}
          </div>
          <div class="toggle pa-2 white" v-if="!team.players.length">
            <v-icon
              v-if="team.inactive"
              color="green"
              @click="toggleTeam(team)"
            >
              mdi-delete-off
            </v-icon>
            <v-icon v-else color="red darken-4" @click="toggleTeam(team)">
              mdi-delete
            </v-icon>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col cols="12">
        <draggable
          v-model="availablePlayers"
          group="players"
          class="not-assigned px-4"
        >
          <Player
            v-for="player in availablePlayers"
            :key="player.id"
            :player="player"
            :withParent="player.parent_helper == 1"
            class="mr-12 my-3 player"
          />
        </draggable>
      </v-col>
    </v-row>
    <v-row class="mt-4 no-print">
      <v-col cols="12" class="text-right">
        <v-btn
          :disabled="allocatedPlayersCount === 0"
          class="ma-2"
          @click="clear"
        >
          Clear
        </v-btn>
        <v-btn
          color="green"
          class="ma-2"
          :dark="availablePlayers.length > 0"
          :disabled="availablePlayers.length === 0"
          @click="allocate"
        >
          Random Allocation
        </v-btn>
        <v-btn
          :disabled="allocatedPlayersCount === 0"
          class="ma-2"
          @click="print"
        >
          <v-icon left> mdi-printer </v-icon>
          Print
        </v-btn>
        <v-btn color="primary" class="ma-2" large @click="save">
          <v-icon left> mdi-content-save </v-icon>
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { EventBus } from '@/event-bus'
import ApiService from '@/services/ApiService'
import draggable from 'vuedraggable'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'
import PrintableMixin from '@/mixins/PrintableMixin'
import Player from '@/components/chips/Player'
import Progress from '@/components/utils/Progress'
import RoundNavigator from '@/components/admin/RoundNavigator'

export default {
  name: 'TeamsAllocation',
  components: {
    draggable,
    Player,
    Progress,
    RoundNavigator,
  },
  mixins: [FixtureBasedMixin, PrintableMixin],
  data: () => ({
    ageGroup: 'u6',
    clubId: 1,
    loading: true,
    allPlayers: [],
    availablePlayers: [],
    clubTeams: [],
    teams: [],
  }),
  computed: {
    allocatedPlayersCount() {
      let count = 0
      this.teams.forEach((team) => (count += team.players.length))
      return count
    },
  },
  async mounted() {
    this.loading = true
    try {
      this.allPlayers = (
        await ApiService.get('teams', 'availablePlayers', {
          fixture_id: this.fixture.id,
        })
      ).data
      this.teams = (await ApiService.get('fixture/teams', this.fixture.id)).data
      this.clubTeams = (
        await ApiService.get('clubs/teams', this.ageGroup)
      ).data.filter((clubTeam) => clubTeam.club_id === this.clubId)
      var games = (
        await ApiService.get('games', 'list', {
          ageGroup: this.ageGroup,
          fixtureId: this.fixture.id,
        })
      ).data
      if (this.teams.length) {
        this.teams.forEach((team) => {
          if (team.players.length) {
            team.players = team.players
              .map((player) => this.fullPlayerData(player.id))
              .filter((player) => !!player)
          } else {
            team.inactive = true
          }
          team.pitches = [
            ...new Set(
              games
                .filter(
                  (game) =>
                    game.home_team_id === team.club_team_id ||
                    game.away_team_id === team.club_team_id,
                )
                .map((game) => game.pitch),
            ),
          ].join(', ')
        })
      } else {
        this.clubTeams.forEach((clubTeam) => {
          this.teams.push({
            club_team_id: clubTeam.id,
            name: clubTeam.name,
            players: [],
          })
        })
      }
      this.availablePlayers = this.allPlayers.filter(
        (player) =>
          !this.teams.some((team) =>
            team.players.some((teamPlayer) => teamPlayer.id === player.id),
          ),
      )
    } finally {
      this.loading = false
    }
  },
  methods: {
    fullPlayerData(playerId) {
      return this.allPlayers.find((player) => player.id === playerId)
    },
    playersWithHelpers(players, available) {
      return players.filter(
        (player) => player.parent_helper === (available ? 1 : 0),
      )
    },
    leader(team, index) {
      const existing = index ? team.helper : team.game_leader
      if (existing) {
        return existing
      }
      const player = this.playersWithHelpers(team.players, true)[index]
      return player
        ? `${player.parent_first_name} (${player.first_name} ${player.last_name_initial})`
        : ''
    },
    clear() {
      this.teams.forEach((team) => {
        this.availablePlayers.push(...team.players)
        team.players = []
        delete team.game_leader
        delete team.helper
      })
    },
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return [...array]
    },
    shuffleAndAllocate(teams, players, currentTeamIndex) {
      if (teams.length && players.length) {
        this.shuffle(players).forEach((player) => {
          teams[currentTeamIndex++ % teams.length].players.push(player)
        })
      }
      return currentTeamIndex
    },
    allocate() {
      const withHelpers = this.playersWithHelpers(this.availablePlayers, true)
      const maxTeams = Math.min(
        Math.floor(this.allPlayers.length / 4),
        this.teams.length,
      )
      const teamsToAllocate = this.teams
        .filter((team) => !team.inactive)
        .slice(0, maxTeams || 1)
      if (teamsToAllocate.length) {
        let teamIndex = this.shuffleAndAllocate(teamsToAllocate, withHelpers, 0)
        const withoutHelpers = this.playersWithHelpers(
          this.availablePlayers,
          false,
        )
        this.shuffleAndAllocate(teamsToAllocate, withoutHelpers, teamIndex)
        this.teams.forEach((team) => {
          if (!team.players.length) {
            team.inactive = true
          }
        })
        this.availablePlayers = []
      }
    },
    toggleTeam(team) {
      team.inactive = !team.inactive
      this.teams = [...this.teams]
    },
    async save() {
      this.loading = true
      let error = false
      let message = 'Teams have been successfully saved'
      try {
        const payload = this.teams.map((team) => ({
          fixture_id: this.fixture.id,
          club_team_id: team.club_team_id,
          game_leader: this.leader(team, 0),
          helper: this.leader(team, 1),
          players: team.players.map((player) => player.id),
        }))
        await ApiService.create('teams', payload).data
      } catch (exc) {
        error = true
        message = 'Could not save teams'
        console.error(exc)
      }
      EventBus.$emit('feedback', { message, error })
      this.loading = false
    },
  },
}
</script>

<style>
.team-players .player {
  display: block;
}
.team-players .player .v-chip {
  min-width: 4.5rem;
}
</style>

<style scoped>
.team {
  position: relative;
}
.inactive {
  text-decoration: line-through;
}
.player {
  cursor: move;
}
.leader-name {
  white-space: nowrap;
}
.not-assigned,
.team-players {
  min-height: 4rem;
  padding: 8px;
  border: 1px dashed lightgray;
}
.toggle,
.pitches {
  position: absolute;
  bottom: 0;
  right: 0;
}
.toggle {
  cursor: pointer;
}
.pitches {
  line-height: 1rem;
}

@media print {
  .row > div {
    padding: 5px;
  }
  .team {
    padding: 10px 10px -10px 10px;
    line-height: 1rem;
  }
  .team-players {
    border: 0;
    margin-top: -8px !important;
  }
  .team-players div:last-child {
    margin-bottom: 0 !important;
  }
  .player {
    margin-left: -1rem;
    margin-bottom: 10px !important;
  }
}
</style>
