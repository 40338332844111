<template>
  <v-row>
    <Progress v-if="loading" />
    <template v-else>
      <v-col cols="12" sm="6">
        <v-card elevation="0">
          <v-card-text>
            <p class="text-h5">{{ team.name }}</p>
            <p>
              Game Leader: <b>{{ team.game_leader }}</b>
            </p>
            <p class="pb-2">
              Helper: <b>{{ team.helper }}</b>
            </p>
            <div v-for="player in sortedPlayers" :key="player.id">
              <Player
                :player="player"
                class="mb-4 block"
                @click="$emit('player-click')"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" v-if="games.length">
        <v-card elevation="0">
          <v-card-text>
            <p class="text-h5">Games</p>
            <p>
              <GameInfo v-for="game in games" :key="game.id" :game="game" />
            </p>
            <v-btn color="green" dark class="my-4" @click="clusterFixtures()">
              <v-icon left> mdi-soccer </v-icon>
              Full List of Fixtures
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="text-body-2">
        <p v-if="fixture.venue_id === 2" class="text-justify">
          As the games are at home this week, please come down a little early
          (at least 30mins) to help out if extra jobs pop up and to make sure
          the kids are warmed up.
        </p>
        <p class="text-justify">
          A reminder for Game Helpers to stick close to your subs and keep track
          of the time rotation for equal game play. Please also remember to
          <b>meet back up after the games</b> for the Match Day certificate to
          be handed out (find Coach Nick F). If you have any questions, please
          post in the WhatsApp group.
        </p>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { format, parse } from 'date-fns'
import ApiService from '@/services/ApiService'
import GameInfo from '@/components/info/GameInfo'
import Player from '@/components/chips/Player'
import Progress from '@/components/utils/Progress'

export default {
  name: 'TeamInfo',
  components: {
    GameInfo,
    Player,
    Progress,
  },
  props: {
    team: Object,
    fixture: Object,
    venueId: String,
  },
  data: () => ({
    loading: true,
    ageGroup: 'u6',
    games: [],
  }),
  async mounted() {
    this.loading = true
    try {
      this.games = (
        await ApiService.find('games', {
          teamId: this.team.club_team_id,
          fixtureId: this.fixture.id,
        })
      ).data
    } finally {
      this.loading = false
    }
  },
  computed: {
    ...mapState(['player']),
    sortedPlayers() {
      return this.team.players.slice().sort((player1, player2) => {
        const number1 = player1.id === this.player.id ? -1 : player1.number
        const number2 = player2.id === this.player.id ? -1 : player2.number
        return number1 - number2
      })
    },
  },
  methods: {
    textTime(isoTime) {
      return format(parse(isoTime, 'HH:mm:ss', new Date()), 'h:mmaaa')
    },
    clusterFixtures() {
      this.$router.push({
        name: 'cluster-fixtures',
        params: {
          ...this.$route.params,
          ageGroup: this.ageGroup,
        },
      })
    },
  },
}
</script>
