<template>
  <v-row align="center" dense>
    <v-col cols="3" align="center" class="text-caption">
      {{ game.pitch }}
    </v-col>
    <v-col cols="4" align="center">
      <Team :name="game.home_team" :color="game.home_team_color" />
    </v-col>
    <v-col cols="4" align="center">
      <Team :name="game.away_team" :color="game.away_team_color" />
    </v-col>
  </v-row>
</template>

<script>
import Team from '@/components/chips/Team'

export default {
  name: 'GameInfo',
  components: {
    Team,
  },
  props: {
    game: {},
  },
}
</script>
