import Vue from 'vue'
import Vuex from 'vuex'

import {
  SET_USER,
  SET_FIXTURES,
  SET_PLAYER,
  SET_ITEMS_PER_PAGE,
  SET_LIST_PAGE,
  SET_LIST_OPTIONS,
  SET_LIST_FILTERS,
} from './mutation-types'

Vue.use(Vuex)

const defaultListOptions = {
  sortBy: ['name'],
  sortDesc: [false],
  mustSort: true,
  multiSort: false,
}

import { endOfDay, isAfter, parse } from 'date-fns'

export default new Vuex.Store({
  state: {
    user: null,
    menu: [
      {
        label: 'Teams Allocation',
        icon: 'mdi-soccer-field',
        route: '/admin/2022/u6/teams',
      },
      // {
      //   label: 'Games',
      //   icon: 'mdi-soccer',
      //   route: '/admin/2022/u6/games',
      // },
      {
        label: 'Availability',
        icon: 'mdi-account-check',
        route: '/admin/2022/u6/availabilities',
      },
      {
        label: 'Players',
        icon: 'mdi-tshirt-crew',
        route: '/admin/2022/u6/players',
      },
      // {
      //   label: 'Fixtures',
      //   icon: 'mdi-clipboard-text',
      //   route: '/admin/2022/u6/fixtures',
      // },
      {},
      {
        label: 'Fixtures Builder',
        icon: 'mdi-calendar-collapse-horizontal',
        route: '/admin/2022/u6/fixtures-builder',
      },
    ],
    fixtures: [],
    player: null,
    itemsPerPage: 10,
    listPage: {
      fixtures: 1,
      players: 1,
      availabilities: 1,
    },
    listOptions: {
      fixtures: {
        ...defaultListOptions,
        sortBy: ['date'],
      },
      players: {
        ...defaultListOptions,
        sortBy: ['first_name'],
      },
      availabilities: {
        ...defaultListOptions,
        sortBy: ['available'],
        sortDesc: [true],
      },
    },
    listFilters: {
      fixtures: {},
      players: {},
      availabilities: {},
    },
  },
  mutations: {
    [SET_USER](state, payload) {
      state.user = payload
    },
    [SET_FIXTURES](state, payload) {
      state.fixtures = payload
    },
    [SET_PLAYER](state, payload) {
      state.player = payload
    },
    [SET_ITEMS_PER_PAGE](state, payload) {
      state.itemsPerPage = payload.itemsPerPage
      state.listPage[payload.list] = 1
    },
    [SET_LIST_PAGE](state, payload) {
      state.listPage[payload.list] = payload.page
    },
    [SET_LIST_OPTIONS](state, payload) {
      state.listOptions[payload.list] = payload.options
    },
    [SET_LIST_FILTERS](state, payload) {
      state.listFilters[payload.list] = payload.options
    },
  },
  actions: {
    setUser({ commit }, payload) {
      commit(SET_USER, payload)
      localStorage.setItem('user', JSON.stringify(payload))
    },
    setFixtures({ commit }, payload) {
      commit(SET_FIXTURES, payload)
    },
    setPlayer({ commit }, payload) {
      commit(SET_PLAYER, payload)
      localStorage.setItem('player', JSON.stringify(payload))
    },
    setItemsPerPage({ commit }, payload) {
      commit(SET_ITEMS_PER_PAGE, payload)
    },
    setListPage({ commit }, payload) {
      commit(SET_LIST_PAGE, payload)
    },
    setListOptions({ commit }, payload) {
      commit(SET_LIST_OPTIONS, payload)
    },
    setListFilters({ commit }, payload) {
      commit(SET_LIST_FILTERS, payload)
    },
  },
  getters: {
    loggedIn: (state) => () => {
      return state.user && state.user.token
    },
    currentFixture: (state, getters) => () => {
      let currentFixture = state.fixtures.find((fixture) =>
        isAfter(
          endOfDay(
            parse(
              `${fixture.date} ${fixture.time}`,
              'yyyy-MM-dd HH:mm:ss',
              new Date(),
            ),
          ),
          new Date(),
        ),
      )
      if (!currentFixture) {
        currentFixture = getters.lastFixture()
      }
      return currentFixture
    },
    lastFixture: (state) => () => {
      return state.fixtures[state.fixtures.length - 1]
    },
  },
})
