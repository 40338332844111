<template>
  <v-footer app>
    <div class="caption" @click="showLinks = true">#{{ version }}</div>
    <v-bottom-sheet v-model="showLinks">
      <v-sheet class="text-center pa-5">
        <v-btn class="ma-2" @click="goTo('/')" color="primary">
          <v-icon left> mdi-account </v-icon>
          Floreat U6 Parents
        </v-btn>
        <v-btn class="ma-2 mr-16" @click="goTo('/cluster')" dark color="black">
          <v-icon left> mdi-account </v-icon>
          Cluster 13 Parents
        </v-btn>
        <v-btn class="ma-2 ml-16" @click="goTo('/admin')" color="primary">
          <v-icon left> mdi-lock </v-icon>
          Admin Section
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
    <div class="caption"></div>
    <v-spacer></v-spacer>
    <div class="caption">
      by
      <a
        href="http://keepapp.com.au"
        target="_blank"
        class="keepapp font-weight-bold"
        >KeepApp</a
      >
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    version: String,
  },
  data: () => ({
    showLinks: false,
  }),
  methods: {
    goTo(path) {
      this.$router.push(path)
      this.showLinks = false
    },
  },
}
</script>

<style scoped>
.keepapp {
  color: #0ba4b6;
}
</style>
