<template>
  <v-row class="mt-1">
    <v-col class="d-flex justify-center">
      <v-sheet width="1024" rounded="lg" class="pa-6">
        <v-row>
          <v-col class="text-h6"> Availability </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              filled
              label="Date"
              :value="dateText"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              filled
              label="Venue"
              :value="fixture.venue"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <Registration
          :admin="true"
          :playerId="playerId"
          :fixture="fixture"
          @saved="back"
          @cancel="back"
        />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import Registration from '@/components/info/Registration'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'

export default {
  name: 'GameForm',
  mixins: [FixtureBasedMixin],
  components: {
    Registration,
  },
  props: {
    playerId: [Number, String],
  },
  methods: {
    back() {
      this.$router.push({
        name: 'availabilities',
        params: { round: this.fixture.number },
      })
    },
  },
}
</script>
