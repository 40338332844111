import { mapGetters, mapState } from 'vuex'
import { add, format, parse, parseISO } from 'date-fns'

export default {
  data: () => ({
    fixture: {},
  }),
  computed: {
    ...mapState(['fixtures']),
    ...mapGetters(['currentFixture', 'lastFixture']),
    dateText() {
      return this.date ? format(this.date, 'eeee, MMMM do') : ''
    },
    dateTime() {
      if (this.fixture.time) {
        let timeValue = parse(this.fixture.time, 'HH:mm:ss', new Date())
        // hack
        if (this.$route.params.ageGroup === 'u7') {
          timeValue = add(timeValue, { hours: 1 })
        }
        return format(timeValue, 'h:mmaaa')
      }
    },
    date() {
      return this.fixture.date ? parseISO(this.fixture.date) : null
    },
  },
  created() {
    if (this.$route.params.round) {
      this.fixture = this.fixtures[this.$route.params.round - 1]
    } else {
      this.fixture = this.currentFixture()
    }
  },
}
