<template>
  <v-row class="mt-1">
    <v-col class="d-flex justify-center">
      <v-sheet max-width="1024" rounded="lg" class="pa-6">
        <v-row>
          <v-col cols="2">
            <router-link
              v-if="fixture.number > 1"
              :to="{
                name: this.$route.name,
                params: { round: fixture.number - 1 },
              }"
            >
              <v-icon x-large>mdi-chevron-left</v-icon>
            </router-link>
          </v-col>
          <v-col align="center" class="text-h4 mb-6" cols="8">
            Round {{ fixture.number }}
          </v-col>
          <v-col align="right" cols="2">
            <router-link
              v-if="fixture !== lastFixture()"
              :to="{
                name: this.$route.name,
                params: { round: fixture.number + 1 },
              }"
            >
              <v-icon x-large>mdi-chevron-right</v-icon>
            </router-link>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              label="Date"
              :value="dateText"
              prepend-icon="mdi-calendar"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Time"
              :value="dateTime"
              prepend-icon="mdi-clock"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="7" sm="4">
            <v-text-field
              label="Venue"
              :value="fixture.venue"
              prepend-icon="mdi-stadium"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="5" sm="2" class="pt-6" align="right">
            <v-btn small outlined color="primary" @click="showMap = !showMap">
              <v-icon left> mdi-map </v-icon>
              {{ showMap ? 'Hide' : 'Show' }} map
            </v-btn>
          </v-col>
          <v-col cols="7" sm="4">
            <v-text-field
              label="Address"
              :value="fixture.address"
              prepend-icon="mdi-map-marker"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="5" sm="2" class="pt-6" align="right">
            <v-btn
              small
              outlined
              color="primary"
              :href="`https://www.google.com/maps/dir/?api=1&destination=${fixture.latitude},${fixture.longitude}`"
              target="_blank"
            >
              <v-icon left> mdi-directions </v-icon>
              Directions
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showMap">
          <v-col cols="12" class="px-6">
            <Map
              :latitude="parseFloat(fixture.latitude)"
              :longitude="parseFloat(fixture.longitude)"
            />
          </v-col>
        </v-row>
        <slot />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'
import Map from '@/components/fields/Map'

export default {
  name: 'FixtureDetails',
  mixins: [FixtureBasedMixin],
  components: {
    Map,
  },
  data: () => ({
    showMap: false,
  }),
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
