<template>
  <v-card flat class="px-4 pb-6">
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="6">
          <span class="mr-4">Players</span>
        </v-col>
        <v-col cols="12" sm="6" class="text-right">
          <v-btn v-if="false" color="primary" dark class="mb-2" @click="add">
            <v-icon left> mdi-plus </v-icon> Add Player
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table v-bind="listProps" v-on="listEvents">
      <template v-slot:[`item.number`]="{ item }">
        <Player :player="item" :hideName="true" />
      </template>
      <template v-slot:[`item.old_number`]="{ item }">
        <Player
          :player="{ ...item, number: item.old_number }"
          :hideName="true"
        />
      </template>
    </v-data-table>
    <ListFooter
      :module="module"
      @updated="loadData"
      :itemsCount="listData.total"
    ></ListFooter>
  </v-card>
</template>

<script>
import ListMixin from '@/mixins/ListMixin'
import Player from '@/components/chips/Player'

export default {
  name: 'PlayersList',
  components: {
    Player,
  },
  mixins: [ListMixin],
  data() {
    return {
      module: 'players',
      formRoute: null,
      headers: [
        { text: 'Number', value: 'number' },
        { text: 'Old Number', value: 'old_number' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name Initial', value: 'last_name_initial' },
      ],
    }
  },
}
</script>
