<template>
  <v-row>
    <v-col class="mb-2 no-print">
      <div>{{ title }} for Round {{ fixture.number }}</div>
      <div class="text-subtitle-1">{{ dateText }} at {{ fixture.venue }}</div>
    </v-col>
    <v-col class="no-print" align="center" v-if="ageGroup">
      <AgeGroupSwitcher :ageGroup="ageGroup" />
    </v-col>
    <v-col class="no-print" align="right">
      <slot />
      <v-btn
        outlined
        color="primary"
        class="mr-4"
        @click="previous"
        :disabled="fixture.number === 1"
      >
        <v-icon left> mdi-chevron-left </v-icon>
        Previous
      </v-btn>
      <v-btn
        outlined
        color="primary"
        @click="next"
        :disabled="fixture === lastFixture()"
      >
        Next
        <v-icon right> mdi-chevron-right </v-icon>
      </v-btn>
    </v-col>
    <v-col class="print-only">
      {{ title }} for Round {{ fixture.number }}
    </v-col>
    <v-col class="print-only text-subtitle-1" align="right">
      {{ dateText }} at {{ fixture.venue }}
    </v-col>
  </v-row>
</template>

<script>
import AgeGroupSwitcher from '@/components/admin/AgeGroupSwitcher'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'

export default {
  name: 'RoundNavigator',
  mixins: [FixtureBasedMixin],
  components: {
    AgeGroupSwitcher,
  },
  props: {
    title: String,
    ageGroup: String,
  },
  methods: {
    previous() {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          round: this.fixture.number - 1,
        },
      })
    },
    next() {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          round: this.fixture.number + 1,
        },
      })
    },
  },
}
</script>
