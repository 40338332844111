<template>
  <v-row>
    <Progress v-if="loading" />
    <FixtureDetails>
      <template v-if="!loading">
        <TeamInfo
          v-if="team"
          :team="team"
          :fixture="fixture"
          @player-click="changePlayer"
        />
        <Registration
          v-else
          :playerId="player.id"
          :fixture="fixture"
          @player-click="changePlayer"
        />
      </template>
    </FixtureDetails>
  </v-row>
</template>

<script>
import ApiService from '@/services/ApiService'
import { mapState } from 'vuex'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'
import FixtureDetails from '@/components/info/FixtureDetails'
import Progress from '@/components/utils/Progress'
import Registration from '@/components/info/Registration'
import TeamInfo from '@/components/info/TeamInfo'

export default {
  name: 'FixtureInfo',
  mixins: [FixtureBasedMixin],
  components: {
    FixtureDetails,
    Progress,
    Registration,
    TeamInfo,
  },
  data: () => ({
    teams: [],
    loading: false,
  }),
  computed: {
    ...mapState(['player']),
    team() {
      return this.teams.find((team) =>
        team.players.some((teamPlayer) => teamPlayer.id === this.player.id),
      )
    },
  },
  watch: {
    player() {
      this.loadData()
    },
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      try {
        this.teams = (
          await ApiService.get('fixture/teams', this.fixture.id)
        ).data
      } finally {
        this.loading = false
      }
    },
    async changePlayer() {
      await this.$store.dispatch('setPlayer', null)
      this.fillingIn = true
    },
  },
}
</script>
