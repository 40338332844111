<template>
  <v-row class="mt-1">
    <v-col class="d-flex justify-center">
      <v-sheet width="1024" rounded="lg" class="pa-6">
        <v-form v-model="valid">
          <Progress v-if="loading" />
          <v-row>
            <v-col class="text-h6"> <span v-if="!id">New </span>Game </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                filled
                label="Date"
                :value="dateText"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                filled
                label="Venue"
                :value="fixture.venue"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                filled
                v-model="record.time"
                label="Time *"
                :rules="[
                  (v) =>
                    /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/.test(v) ||
                    'Time must be valid',
                ]"
                ref="time"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                filled
                v-model="record.team_id"
                :items="teams"
                item-text="name"
                item-value="id"
                label="Team *"
                :rules="[(v) => !!v || 'Team is required']"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                filled
                v-model="record.opposition"
                label="Opposition *"
                maxlength="255"
                :rules="[(v) => !!v || 'Opposition is required']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-color-picker
                :value="record.opposition_color"
                @update:color="record.opposition_color = $event.hex"
                hide-canvas
                hide-inputs
                hide-sliders
                show-swatches
                :swatches="[
                  ['#FE0000', '#FF0080'],
                  ['#FF7F00', '#FF00FE'],
                  ['#FFFF00', '#7F00FF'],
                  ['#80FF00', '#0000FE'],
                  ['#00FF00', '#0080FF'],
                ]"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                filled
                v-model="record.pitch_id"
                :items="pitches"
                item-text="name"
                item-value="id"
                label="Pitch *"
                :rules="[(v) => !!v || 'Pitch is required']"
              ></v-select>
            </v-col>
          </v-row>
          <FormButtons
            :valid="valid"
            :showDelete="!!id"
            recordType="Game"
            @save="save"
            @cancel="back"
            @delete="remove"
          />
        </v-form>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import ApiService from '@/services/ApiService'
import FixtureBasedMixin from '@/mixins/FixtureBasedMixin'
import FormMixin from '@/mixins/FormMixin'

export default {
  name: 'GameForm',
  mixins: [FixtureBasedMixin, FormMixin],
  data: () => ({
    type: 'Game',
    module: 'games',
    teams: [],
    pitches: [],
    selectedColor: null,
  }),
  computed: {
    timeValue() {
      return this.record.time
    },
  },
  watch: {
    timeValue(newValue) {
      const parts = newValue.split(':')
      if (parts.length > 2) {
        this.record.time = `${parts[0]}:${parts[1]}`
      }
    },
  },
  async mounted() {
    this.loading = true
    try {
      const lookups = (
        await ApiService.get('game', 'lookups', { fixture_id: this.fixture.id })
      ).data
      this.teams = lookups.teams
      this.pitches = lookups.pitches
      setTimeout(() => {
        this.$refs.time.focus()
      }, 300)
    } finally {
      this.loading = false
    }
  },
  methods: {
    listRouteParams() {
      return {
        round: this.$route.params.round,
      }
    },
  },
}
</script>
