<template>
  <div>
    <div class="text-center" v-if="loggedIn()">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-chip color="white" class="primary--text" v-on="on" v-bind="attrs">
            {{ user.name }}
            <v-icon right> mdi-account-outline </v-icon>
          </v-chip>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title @click.stop="editProfile = true"
              >Edit Profile</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="logout">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <LoginForm v-else />
    <v-dialog v-if="loggedIn()" v-model="editProfile" width="800">
      <v-card v-if="editProfile">
        <UserForm popup :id="user.id" @back="refresh" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ApiService from '@/services/ApiService'
import LoginForm from '@/components/admin/LoginForm'
import UserForm from '@/components/admin/UserForm'

export default {
  name: 'UserInfo',
  components: {
    LoginForm,
    UserForm,
  },
  data: () => ({
    editProfile: false,
  }),
  computed: {
    ...mapState(['user']),
    ...mapGetters(['loggedIn']),
  },
  async beforeCreate() {
    const userData = localStorage.getItem('user')
    if (userData) {
      await this.$store.dispatch('setUser', JSON.parse(userData))
    }
  },
  methods: {
    async logout() {
      await ApiService.get('auth', 'logout')
      this.$store.dispatch('setUser', null)
    },
    async refresh() {
      const userData = (await ApiService.get('auth', 'me')).data
      await this.$store.dispatch('setUser', {
        ...userData,
        token: this.user.token,
      })
      this.editProfile = false
    },
  },
}
</script>
