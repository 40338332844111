var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-4 pb-6",attrs:{"flat":""}},[_c('v-card-title',[_c('RoundNavigator',{attrs:{"title":"Availability"}})],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{staticClass:"text-subtitle-1 gray--text",attrs:{"cols":"12"}},[_c('Available',{attrs:{"available":1,"label":("Available: " + (_vm.count(1)))}}),_c('Available',{attrs:{"available":0,"label":("Not available: " + (_vm.count(0)))}}),_c('Available',{attrs:{"available":null,"label":("Unknown: " + (_vm.count(null)))}})],1)],1),_c('v-data-table',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('Player',{attrs:{"player":item}})]}},{key:"item.available",fn:function(ref){
var item = ref.item;
return [_c('Available',{attrs:{"available":item.available}})]}},{key:"item.parent_helper",fn:function(ref){
var item = ref.item;
return [(!!item.parent_helper)?_c('Available',{attrs:{"available":item.parent_helper,"label":item.parent_first_name}}):_vm._e()]}},{key:"item.venue_helper",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[(!!item.venue_helper)?_c('Available',{attrs:{"available":item.venue_helper,"label":item.helper_first_name}}):_vm._e(),(item.venue_helper)?_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.helperRoles[item.id])+" ")]):_vm._e()],1)]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at ? _vm.textTimestamp(item.updated_at) : _vm.textTimestamp(item.created_at))+" ")]}}],null,true)},'v-data-table',_vm.listProps,false),_vm.listEvents))],1)}
var staticRenderFns = []

export { render, staticRenderFns }