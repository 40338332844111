<template>
  <div>
    <v-app-bar app flat dark color="primary">
      <v-spacer></v-spacer>
      <div class="text-h6">Floreat Athena - 2022 - Under 6</div>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-container fluid>
        <PlayerSelector v-if="!playerSet" />
        <router-view v-else :key="`subroute-${$route.path}`" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ApiService from '@/services/ApiService'
import PlayerSelector from '@/components/info/PlayerSelector'

export default {
  name: 'InfoSection',
  components: {
    PlayerSelector,
  },
  computed: {
    ...mapState(['player', 'fixtures']),
    playerSet() {
      return this.player && this.player.id
    },
  },
  async beforeCreate() {
    const playerData = localStorage.getItem('player')
    if (playerData) {
      let player = JSON.parse(playerData)
      if (!player.old_number) {
        player = (
          await ApiService.find('players', {
            number: player.number,
            first_name: player.first_name,
          })
        ).data
      }
      await this.$store.dispatch('setPlayer', player)
    }
  },
}
</script>
