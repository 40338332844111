var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-4 pb-6",attrs:{"flat":""}},[_c('v-card-title',[_c('RoundNavigator',{attrs:{"title":"Games"}},[(!_vm.clusterGames)?_c('v-btn',{staticClass:"mr-8",attrs:{"color":"green","dark":""},on:{"click":_vm.add}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add Game ")],1):_vm._e()],1)],1),_c('v-alert',{staticClass:"mt-4",attrs:{"type":"warning","outlined":""}},[_vm._v(" Currently can only be set in "),_c('router-link',{attrs:{"to":{
        name: 'fixtures-builder',
        params: { ageGroup: this.ageGroup, round: this.fixture.number },
      }}},[_vm._v(" Fixture Builder ")])],1),(false)?_c('v-data-table',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"item.team",fn:function(ref){
      var item = ref.item;
return [_c('Team',{attrs:{"name":item.team,"color":"primary"}})]}},{key:"item.opposition",fn:function(ref){
      var item = ref.item;
return [_c('Team',{attrs:{"name":item.opposition,"color":item.opposition_color}})]}},{key:"item.time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.textTime(item.time))+" ")]}}],null,true)},'v-data-table',_vm.listProps,false),_vm.listEvents)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }