<template>
  <div>
    <v-app-bar app flat dark color="black" class="no-print">
      <v-spacer></v-spacer>
      <div class="text-h6 d-md-none">Under {{ ageGroup.substring(1) }}</div>
      <div class="text-h6 d-none d-md-block">
        Football West - Cluster 13 - 2022 - Under {{ ageGroup.substring(1) }}
      </div>
      <v-spacer></v-spacer>
      <AgeGroupSwitcher :ageGroup="ageGroup" />
    </v-app-bar>
    <v-main class="grey lighten-3">
      <v-container fluid>
        <router-view :key="`subroute-${$route.path}`" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AgeGroupSwitcher from '@/components/admin/AgeGroupSwitcher'

export default {
  name: 'ClusterSection',
  components: {
    AgeGroupSwitcher,
  },
  props: {
    ageGroup: String,
  },
}
</script>
