var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-1"},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-sheet',{staticClass:"pa-6",attrs:{"width":"1024","rounded":"lg"}},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.loading)?_c('Progress'):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"text-h6"},[(!_vm.id)?_c('span',[_vm._v("New ")]):_vm._e(),_vm._v("Game ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":"Date","value":_vm.dateText,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":"Venue","value":_vm.fixture.venue,"disabled":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{ref:"time",attrs:{"filled":"","label":"Time *","rules":[
                function (v) { return /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/.test(v) ||
                  'Time must be valid'; } ]},model:{value:(_vm.record.time),callback:function ($$v) {_vm.$set(_vm.record, "time", $$v)},expression:"record.time"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"filled":"","items":_vm.teams,"item-text":"name","item-value":"id","label":"Team *","rules":[function (v) { return !!v || 'Team is required'; }]},model:{value:(_vm.record.team_id),callback:function ($$v) {_vm.$set(_vm.record, "team_id", $$v)},expression:"record.team_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":"Opposition *","maxlength":"255","rules":[function (v) { return !!v || 'Opposition is required'; }]},model:{value:(_vm.record.opposition),callback:function ($$v) {_vm.$set(_vm.record, "opposition", $$v)},expression:"record.opposition"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-color-picker',{attrs:{"value":_vm.record.opposition_color,"hide-canvas":"","hide-inputs":"","hide-sliders":"","show-swatches":"","swatches":[
                ['#FE0000', '#FF0080'],
                ['#FF7F00', '#FF00FE'],
                ['#FFFF00', '#7F00FF'],
                ['#80FF00', '#0000FE'],
                ['#00FF00', '#0080FF'] ]},on:{"update:color":function($event){_vm.record.opposition_color = $event.hex}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"filled":"","items":_vm.pitches,"item-text":"name","item-value":"id","label":"Pitch *","rules":[function (v) { return !!v || 'Pitch is required'; }]},model:{value:(_vm.record.pitch_id),callback:function ($$v) {_vm.$set(_vm.record, "pitch_id", $$v)},expression:"record.pitch_id"}})],1)],1),_c('FormButtons',{attrs:{"valid":_vm.valid,"showDelete":!!_vm.id,"recordType":"Game"},on:{"save":_vm.save,"cancel":_vm.back,"delete":_vm.remove}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }